<template>
  <MyView :meta="{ viewClass: 'view-likes', inFx: 'rightIn' }" ref="MyView">
    <ViewHeader icon="arrowLeft" :title="$locale['words']['likes']" @back="goBack">
      <div slot="right" class="flex-center">
        <a class="view-header-button"><UserCreditsBtn /></a>
        <HamburgerBtn />
      </div>
    </ViewHeader>
    <ViewContent @scroll.native="scrolling">
      <div class="view-likes-content">
        <div class="view-likes-main main">
          <div class="view-likes-list">
            <LikesViewCard v-for="(item, index) in items" :key="index" :item="item" />
          </div>
          <ViewMoreBtn :morepage="morepage" :loading="loading" @click="getDetails((page += 1))" />
        </div>
      </div>
    </ViewContent>
  </MyView>
</template>

<script>
export default {
  components: {
    MyView: () => import("../../views/View.vue"),
    ViewHeader: () => import("../view/ViewHeader.vue"),
    ViewContent: () => import("../view/ViewContent.vue"),
    HamburgerBtn: () => import("../home/HamburgerBtn.vue"),
    UserCreditsBtn: () => import("../user/UserCreditsBtn.vue"),
    ViewMoreBtn: () => import("../widgets/ViewMoreBtn.vue"),
    LikesViewCard: () => import("./LikesViewCard.vue"),
  },
  data: function() {
    return {
      loading: true,
      page: 1,
      limit: 12,
      morepage: false,
    };
  },
  methods: {
    getLikes: async function(page) {
      this.isLoading(true);
      try {
        page = page >= 1 ? page : 1;
        let query = `${this.$route.query.sort ? `&sort=${this.$route.query.sort}` : ""}`;
        query += `post=${this.$route.query.likes}&page=${page}&limit=${this.limit}${query}`;
        this.$store.dispatch("getLikes", {
          query,
          callback: (response) => {
            if (page > 1) {
              this.$store.state.likes.push(...response.data.likes);
            } else {
              this.$store.state.likes = response.data.likes;
            }
            this.morepage = response.data?.likes?.length >= this.limit;
            this.isLoading(false);
          },
        });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
        this.isLoading(false);
      }
    },
    scrolling: function($event) {
      this.$scroller.isBottomScroll($event, () => {
        if (!this.loading && this.morepage) {
          this.getLikes((this.page += 1));
        }
      });
    },
  },
  beforeMount: function() {
    this.getLikes((this.page = 1));
  },
  computed: {
    items: function() {
      return this.$store.state.likes;
    },
  },
};
</script>

<style lang="scss">
.view-likes {
  z-index: 12;
  &-list {
    margin-bottom: 49px;
  }
}
</style>
